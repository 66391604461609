@font-face {
    font-family: 'Graphik';
    font-style: normal;
    font-display: swap;
    font-weight: 700; /* or bold */
    src: local('Graphik'),
    local('Graphik-Bold'),
    url("./Graphik/Graphik-Bold.woff") format('woff');
}
@font-face {
    font-family: 'Graphik';
    font-style: normal;
    font-display: swap;
    font-weight: 600;
    src: local('Graphik'),
    local('Graphik-Semibold'),
    url("./Graphik/Graphik-Semibold.woff") format('woff');
}
@font-face {
    font-family: 'Graphik';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: local('Graphik'),
    local('Graphik-Medium'),
    url("./Graphik/Graphik-Medium.woff") format('woff');
}
@font-face {
    font-family: 'Graphik';
    font-style: normal;
    font-display: swap;
    font-weight: 400; /* or normal */
    src: local('Graphik'),
    local('Graphik-Regular'),
    url("./Graphik/Graphik-Regular.woff") format('woff');
}
@font-face {
    font-family: 'Alternate Gothic No3 D';
    font-style: normal;
    font-display: swap;
    src: local('AlternateGothicNo3'),
    url("./AlternateGothic/AlternateGothicNo3.otf") format('truetype');
}
@font-face {
    font-family: 'Unica77';
    font-style: normal;
    font-display: swap;
    src: local('Unica77LLTT'),
    local('Unica77LLTT-Regular'),
    url("./Unica77/Unica77LLTT-Regular.ttf") format('truetype');
}
