/** The generic css classes that can be used in the whole application */
.wz-g-d-block {
    display: block;
}

.wz-g-d-inline-block {
    display: inline-block;
}

.wz-g-d-flex {
    display: flex;
}

.wz-g-p0 {
    padding: 0;
}

.wz-g-m0 {
    margin: 0;
}

.wz-g-pm0 {
    margin: 0;
    padding: 0;
}


/** The common css classes that can be used by multiple common specific component or pages */
.wz-g-mui-icon-xs {
    display: block;
    font-size: 0.875rem;
}

.wz-g-mui-icon-s {
    display: block;
    font-size: 1rem;
}

.wz-g-mui-icon {
    display: block;
    font-size: 1.25rem;
}

.wz-g-mui-icon-md {
    display: block;
    font-size: 1.5rem;
}

.wz-g-overflow-scroll-hidden-bar {
    overflow: auto;
    white-space: nowrap;
}

.wz-g-overflow-hidden-text-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.wz-g-overflow-scroll-hidden-bar::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
}

.wz-g-cursor-pointer {
    cursor: pointer;
}

.MuiAlertTitle-root {
    font-weight: 600;
}


/** These classes are overrides or definitions of real classes (no new class creation nor mui theme related) */
img[src*='#center'] {
    display: block;
    margin: auto;
}
