body {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  max-width: 768px;
  margin: 0 auto;
}

ul {
  list-style: none;
}

html {
  scroll-behavior: smooth;
}
